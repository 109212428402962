import React, { useEffect } from 'react';
import WOW from "wowjs";
import Menubar from '../Layout/Menubar';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import jaquar from '../assets/img/case-study-jaquar-.jpg'
import jaquar2 from '../assets/img/case-study-jaquar.jpg'
import taxi from '../assets/img/case-study-taxiapp.jpg'
import byjusVideo from '../assets/video/byjus.mp4'
import jaquarVideo from '../assets/video/jaquar.mp4'
import ph_icon_color from '../assets/img/php-icon-clr.svg'
import mean_icon_color from '../assets/img/mean-icon-clr.svg'
import laravel_icon_color from '../assets/img/laravel-icon-clr.svg'
import cake_icon_color from '../assets/img/cake-icon-clr.svg'
import node_icon_color from '../assets/img/node-icon-clr.svg'
import codeignitor_icon_color from '../assets/img/codeignitor-icon-clr.svg'
import email_logo from '../assets/img/emmilogo.png'
import isologo from '../assets/img/isologo.png'
import awspartnerlogo from '../assets/img/awspartnerlogo.png'
import microsoftpartnerlogo from '../assets/img/microsoftpartnerlogo.png'
import techWebsite from '../assets/img/tech-website.png'
import googlepartner from '../assets/img/googlepartner.png'
import designrushlogo from '../assets/img/designrushlogo.png'
import berlitz from '../assets/img/berlitz.png'
import bose from '../assets/img/bose.png'
import dentsu from '../assets/img/dentsu.png'
import dalmia_cement from '../assets/img/dalmia_cement.png'
import michelin from '../assets/img/michelin.png'
import salford from '../assets/img/salford.png'
import phoenix from '../assets/img/phoenix.png'
import nielsen from '../assets/img/nielsen.png'
import jaquarimg from '../assets/img/jaquar.png'
import mawady_logo from '../assets/img/mawady_logo.png'
import lease_plan from '../assets/img/lease_plan.png'
import pearson from '../assets/img/pearson.png'
import clutch_logo from '../assets/img/clutch-logo.svg'
import goodfirm_logo_white_logo from '../assets/img/goodfirm_logo_white.png'
import trust_pilot_white_logo from '../assets/img/trust-pilot-white.png'
import dean_fleature from '../assets/img/Dean-Fletcher.png'
import pascal from '../assets/img/Pascal.png'
import Jean from '../assets/img/Jean-Campbell.png'
import post1 from '../assets/img/blogPost1.jpg'
import post2 from '../assets/img/blogPost2.jpg'
import post3 from '../assets/img/blogPost3.jpg'

import { Col, Nav, NavItem, Row, Tab, TabContainer } from 'react-bootstrap';
import ContactForm from '../Components/ContactForm';
import Footer from '../Layout/Footer';

function Home() {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);
    const testimonialData = [
        {
            heading: 'Top Rated',
            data: 'The highest quality results and client satisfaction',
            image: clutch_logo
        },
        {
            heading: 'Excellent mobile',
            data: 'Top App Development Companies in India Since 1999',
            image: goodfirm_logo_white_logo
        },
        {
            heading: 'Rated Excellent',
            data: 'Top Rated Design Company on Trustpilot',
            image: trust_pilot_white_logo,
        }
    ]
    const secondTestimonialData = [
        {
            heading: 'Quick and professional response',
            data: 'I partnered with TechnoScore for my HTML5 development project. I must say this company is…',
            image: dean_fleature,
            author: 'Dean Fletcher',
            date: 'Dec 21, 2021'
        },
        {
            heading: 'Fantastic collaboration and delivery experience',
            data: 'It was a great experience associating with TechnoScore for my web development requirements. This…',
            image: pascal,
            author: 'Pascal',
            date: 'Dec 21, 2021'
        },
        {
            heading: 'Did What I Wanted On Time!',
            data: 'These guys delivered the mobile app I requested for my business, precise on the specifications…',
            image: Jean,
            author: 'Jean Campbell',
            date: 'Dec 21, 2021'
        },
        {
            heading: 'Quick and professional response',
            data: 'I partnered with TechnoScore for my HTML5 development project. I must say this company is…',
            image: dean_fleature,
            author: 'Dean Fletcher',
            date: 'Dec 21, 2021'
        },
        {
            heading: 'Fantastic collaboration and delivery experience',
            data: 'It was a great experience associating with TechnoScore for my web development requirements. This…',
            image: pascal,
            author: 'Pascal',
            date: 'Dec 21, 2021'
        },
    ]
    const blogData = [
        {
            id: 'post1',
            date: 'June 15, 2023',
            title: "How AI Can Help You Streamline & Redesign Business Processes?",
            text: "Business process re-engineering has got much more fortified with AI capabilities and businesses across the globe are making good use of it. ",
            img: post1
        },
        {
            id: 'post2',
            date: 'March 16, 2023',
            title: "ChatGPT API Integration to Your Application- Scope & Benefits",
            text: "ChatGPT is the latest buzzword in the software development industry. It's an AI-powered chatbot trained on OpenAI's GPT-3.5 family of large language...",
            img: post2
        },
        {
            id: 'post3',
            date: 'February 2, 2023',
            title: "Top Benefits of Using AWS Data Lake to Elevate Business Performance",
            text: "Data is the fuel to boost your business's profitability and to make more informed and smart decisions. A data lake is a fantastic...",
            img: post3
        }
    ]
    const breakpoints = {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 3,
        },
      };

    return (
        <>
            <Menubar />
            <div className="title_sec py-md-5 py-lg-5 py-0">
                <div className="container">
                    <div className="col-lg-9">
                        <h1 className="h_head mb-5 wow fadeInLeft" s data-wow-duration='0.5s'>Building brands that bring out your future.</h1>
                    </div>
                    <div className="year_exp my-5 wow fadeInLeft" data-wow-delay="0.5s">
                        <div className="">
                            <span className="yearexp d-inline-block">
                                20<sup>+</sup>
                            </span>
                            <span className="year d-inline-block">
                                <span>Years
                                    <span className="exp d-block">of Experience</span>
                                </span>
                            </span>
                            <span className="client d-block text-center">95% Recurring Clients</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="caseStudy_sec pb-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <Swiper
                        breakpoints={breakpoints}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={100}
                        slidesPerView={3}
                        centeredSlides={true}
                        initialSlide={1}
                        loop={true}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="media_box">
                                <picture><img className="img-fluid" src={jaquar} alt="" width="500" height="625" /></picture>
                            </div>
                            <h3 className="mt-4">iRide</h3>
                            <p style={{fontSize:"18px"}}>An application for Ski & Snowboard contenders to relish the community access by sharing the experiences, videos, photos or other content with buddy, search for similar interest people and grow the community of Ski & Snowboard players.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="media_box">
                                <video width="400" autoplay playsinline loop muted style={{ pointerEvents: 'none' }}>
                                    <source src={jaquarVideo} type="video/mp4" />
                                    {/* <source src="../assets/video/jaquar.ogg" type="video/ogg" /> */}
                                    Your browser does not support HTML video.
                                </video>
                                <picture><img className="img-fluid" src="assets/img/case-study-jaquar.jpg" alt="" width="500" height="625" /></picture>
                            </div>
                            <h3 className="mt-4">Jaquar</h3>
                            <p style={{fontSize:"18px"}}>A feature-packed app by the renowned brand Jaguar that lets users find dealers, place orders and request custom products or simply ask for quotes.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="media_box">
                                <picture><img className="img-fluid" src={taxi} alt="" width="500" height="625" /></picture>
                            </div>
                            <h3 className="mt-4">Taxi App</h3>
                            <p style={{fontSize:"18px"}}>The Taxi App takes one step forward to add convenience and flexibility to the process of cab-hailing.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="media_box">
                                <video width="400" autoplay playsinline loop muted style={{ pointerEvents: 'none' }}>
                                    <source src={byjusVideo} type="video/mp4" />
                                    {/* <source src={byjusAlt} type="video/ogg" /> */}
                                    Your browser does not support HTML video.
                                </video>
                                <picture><img className="img-fluid" src="assets/img/case-study-byjus.jpg" alt="" width="500" height="625" /></picture>
                            </div>
                            <h3 className="mt-4">BYJU'S Learning</h3>
                            <p style={{fontSize:"18px"}}>Our web development team created a unique multimedia-rich website for a leading e-learning company.</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="media_box">
                                <picture><img className="img-fluid" src={jaquar2} alt="" width="500" height="625" /></picture>
                            </div>
                            <h3 className="mt-4">Jaquar</h3>
                            <p style={{fontSize:"18px"}}>A feature-packed app by the renowned brand Jaguar that lets users find dealers, place orders and request custom products or simply ask for quotes.</p>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div >
            <div className="tech_sec position-relative py-6 overflow-x-hidden my-6 my-md-0 my-lg-0">
                <div className="container">
                    <TabContainer defaultActiveKey={'v-pills-website-tab'}>
                        <div className="row py-lg-4">
                            <div className="col-md-12 small-head mb-lg-5 wow fadeInLeft text-white">Technologies we Serve</div>
                            <div className="col-lg-4 col-xxl-4 wow fadeInLeft">
                                <div className="hscroll">
                                    <Nav variant='pills' className="nav flex-column nav-pills border-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-website-tab" data-bs-toggle="pill" data-bs-target="#v-pills-website" type="button" role="tab" aria-controls="v-pills-website" aria-selected="true"> Website</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-mobileApp-tab" data-bs-toggle="pill" data-bs-target="#v-pills-mobileApp" type="button" role="tab" aria-controls="v-pills-mobileApp" aria-selected="false">Mobile App</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-ecommerce-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ecommerce" type="button" role="tab" aria-controls="v-pills-ecommerce" aria-selected="false">eCommerce</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-cms-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cms" type="button" role="tab" aria-controls="v-pills-cms" aria-selected="false">CMS</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-fullStack-tab" data-bs-toggle="pill" data-bs-target="#v-pills-fullStack" type="button" role="tab" aria-controls="v-pills-fullStack" aria-selected="false">Full Stack</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-techTrends-tab" data-bs-toggle="pill" data-bs-target="#v-pills-techTrends" type="button" role="tab" aria-controls="v-pills-techTrends" aria-selected="false">Tech Trends</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-cloud-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cloud" type="button" role="tab" aria-controls="v-pills-cloud" aria-selected="false">Cloud</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-microsoft-tab" data-bs-toggle="pill" data-bs-target="#v-pills-microsoft" type="button" role="tab" aria-controls="v-pills-microsoft" aria-selected="false">Microsoft</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link href="#" className="nav-link" eventKey="v-pills-designWeb-tab" data-bs-toggle="pill" data-bs-target="#v-pills-designWeb" type="button" role="tab" aria-controls="v-pills-designWeb" aria-selected="false">Design to web</Nav.Link></Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <Tab.Content className="tab_cont_wrap position-relative col-lg-8 col-xxl-7 offset-xxl-1 wow fadeInRight">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <Tab.Pane className="tab-pane fade" id="v-pills-website" role="tabpanel" eventKey="v-pills-website-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Website Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We have over a decade of experience designing and developing dynamic websites using advanced technologies like PHP, ASP.NET, MySQL and more.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="hiiiiiiiiiii" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-mobileApp" role="tabpanel" eventKey="v-pills-mobileApp-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Mobile App Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We are an offshore Mobile App Development Company, with over a decade of experience in delivering feature-packed hybrid and native apps that are high on performance.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">IOS</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Android</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Xamarin</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">React Native</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-ecommerce" role="tabpanel" eventKey="v-pills-ecommerce-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - eCommerce Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We have over a decade of eCommerce development experience spanning diverse industry verticals. Our team of qualified designers and developers can help leverage robust eCommerce platforms like Magento, OpenCart, Shopify, nopCommerce, WooCommerce and More to transform your online store into an Intuitive and Delightful Shopping Experience.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">WordPress</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Magento</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Drupal</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">Nopcommerce</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">Open Cart</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Moodle</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Shopify</span></li>

                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-cms" role="tabpanel" eventKey="v-pills-cms-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - CMS Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>Comprehensive design can enhance your brand value and it's goals. All this starts with ambition and sharing ideas. We can help to build your brand visibility with contemporary design, craft & care. We help discover your ideas, express it and execute as per your expectations.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-fullStack" role="tabpanel" eventKey="v-pills-fullStack-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Full Stack Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>Backend development is a crucial part for any application, it connects the database, manage user connections, power the application itself. We have certified engineers to work on various technologies including open-source, Microsoft or mobile technologies.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-techTrends" role="tabpanel" eventKey="v-pills-techTrends-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Tech Trends Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We have over a decade of experience designing and developing dynamic websites using advanced technologies like PHP, ASP.NET, MySQL and more.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-cloud" role="tabpanel" eventKey="v-pills-cloud-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Cloud Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We have over a decade of experience designing and developing dynamic websites using advanced technologies like PHP, ASP.NET, MySQL and more.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-microsoft" role="tabpanel" eventKey="v-pills-microsoft-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Microsoft Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We have over a decade of experience designing and developing dynamic websites using advanced technologies like PHP, ASP.NET, MySQL and more.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="v-pills-designWeb" role="tabpanel" eventKey="v-pills-designWeb-tab">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-7 col-xl-6">
                                                <h3 className="clrwhite">Top-Notch - Design to Web Development Company in India</h3>
                                                <p style={{fontSize:"18px"}}>We have over a decade of experience designing and developing dynamic websites using advanced technologies like PHP, ASP.NET, MySQL and more.</p>
                                                <ul className="tech_list list-inline mt-4 mb-4 mb-lg-4">
                                                    <li className="list-inline-item"><a href="#"><img src={ph_icon_color} alt="" /></a><span className="d-block">PHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={mean_icon_color} alt="" /></a><span className="d-block">Mean</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={laravel_icon_color} alt="" /></a><span className="d-block">Laravel</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={cake_icon_color} alt="" /></a><span className="d-block">CakePHP</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={node_icon_color} alt="" /></a><span className="d-block">NodeJs</span></li>
                                                    <li className="list-inline-item"><a href="#"><img src={codeignitor_icon_color} alt="" /></a><span className="d-block">Codelgniter</span></li>
                                                </ul>
                                                <p><a className="btn btn-primary" href="#" style={{ padding: '15px 30px' }}><span>Know More</span></a></p>
                                            </div>
                                            <div className="d-none d-lg-block col-md-5 col-lg-5 col-xl-5 offset-xl-1">
                                                <picture><img className="img-fluid" src={techWebsite} alt="" width="427" height="568" /></picture>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </div>
                            </Tab.Content>
                        </div>
                    </TabContainer>
                </div>
            </div>
            <div className="stats_sec my-6 pt-4 py-lg-0 py-md-0">
                <div className="container">
                    <h2 className="f60 font-regular wow fadeInLeft">We have driven results for over 100+ startup businesses in the past 7 years.</h2>
                    <div className="d-flex flex-wrap justify-content-between pt-lg-4">
                        <div className="stats_box text-center mb-5">
                            <span className="stats_value wow fadeInUp" data-wow-delay="0.1s">200+</span>
                            <span className="d-block wow fadeInUp" data-wow-delay="0.7s">Clients working with us <br />from continuous 5 years</span>
                        </div>
                        <div className="stats_box text-center mb-5">
                            <span className="stats_value wow fadeInUp" data-wow-delay="0.3s">1500+</span>
                            <span className="d-block wow fadeInUp" data-wow-delay="0.9s">Full Time Employees</span>
                        </div>
                        <div className="stats_box text-center mb-5">
                            <span className="stats_value wow fadeInUp" data-wow-delay="0.5s">6k+</span>
                            <span className="d-block wow fadeInUp" data-wow-delay="1.1s">Projects Delivered</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partners_sec my-6 py-4 py-lg-0 py-md-0">
                <div className="container pb-lg-5">
                    <h2 className="text-center wow fadeInUp">Accreditation and trusted partnerships</h2>
                    <ul className="partners_list">
                        <li className="wow fadeInUp py-lg-3" data-wow-delay="0.1s" style={{ width: '25%' }}><picture><img src={email_logo} alt="" height="128" width="171" /></picture></li>
                        <li className="wow fadeInUp py-lg-3" data-wow-delay="0.2s" style={{ width: '20%' }}><picture><img src={isologo} alt="" height="134" width="134" /></picture></li>
                        <li className="wow fadeInUp py-lg-3" data-wow-delay="0.3s" style={{ width: '30%' }}><picture><img src={awspartnerlogo} alt="" height="137" width="342" /></picture></li>
                        <li className="wow fadeInUp py-lg-3" data-wow-delay="0.4s" style={{ width: '25%' }}><picture><img src={microsoftpartnerlogo} alt="" height="133" width="266" /></picture></li>
                        <li className="wow fadeInUp py-lg-3" data-wow-delay="0.5s" style={{ width: '25%' }}><picture><img src={googlepartner} alt="" height="94" width="211" /></picture></li>
                        <li className="wow fadeInUp py-lg-3" data-wow-delay="0.6s" style={{ width: '25%' }}><picture><img src={designrushlogo} alt="" height="114" width="319" /></picture></li>
                    </ul>
                </div>
            </div>
            <div class="clients_sec gray-100 py-6">
                <div class="container">
                    <div class="">
                        <div class="text-center small-head wow fadeInLeft">Our Clients</div>
                        <h2 class="h2 text-center clrlightgrey wow fadeInRight">Over 850+ business <span class="d-block d-sm-inline">growing with us</span></h2>
                        <Row class="row row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-lg-6 g-lg-5 pt-lg-5 wow fadeInLeft">
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={berlitz} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={bose} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={dentsu} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={dalmia_cement} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={michelin} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={pearson} alt="" height="130" width="207" /></picture></Col>

                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={salford} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={phoenix} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={nielsen} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={jaquarimg} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={mawady_logo} alt="" height="130" width="207" /></picture></Col>
                            <Col class="col" xl={2}  xs={4}><picture><img class="img-fluid" src={lease_plan} alt="" height="130" width="207" /></picture></Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div class="testimonials_sec pt-6 pb-5">
                <div class="container">
                    <div class="text-center small-head wow fadeInLeft">What our Client says</div>
                    <h2 class="h2 text-center clrlightgrey wow fadeInRight">This is how our clients  <span class="d-block d-sm-inline">praise our work</span></h2>
                </div>
                <div class="trust_wrap position-relative mt-5 pt-5">
                    <div class="container position-relative">
                        <div class="swiper_wrap">
                            <Swiper
                                breakpoints={breakpoints}
                                className='swiper-container   trust_sec  row    clrwhite my-3"'
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={100}
                                slidesPerView={3}
                                centeredSlides={true}
                                initialSlide={1}
                                loop={false}
                                // pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}

                            >
                                {
                                    testimonialData?.map((data, i) => {
                                        return (
                                            <SwiperSlide style={{ margin: '0px', marginRight: "0px" }}>
                                                <div style={{ width: "326px", height: '100%' }} class={`${i == 2 ? '' : 'clutch_box'} d-xl-flex  pb-0 p-md-4 wow fadeInUp`} data-wow-delay="0.1s">
                                                    <div class="trust_logo pb-0 mb-2 mb-lg-0 mb-md-0 p-lg-3 p-md-3"><img class="img-fluid" src={data?.image} alt="" height="89" width="313" /></div>
                                                    <div class="pe-3">
                                                        <h6 class="clrwhite mb-2">{data?.heading}</h6>
                                                        <p style={{textWrap:"wrap",fontSize:"16px"}}>{data?.data}</p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div class="swiper_wrap">
                            <Swiper
                                breakpoints={breakpoints}
                                className='swiper-container   testimonial mt-4 pt-3    pt-md-5  wow fadeInUp'
                                modules={[Pagination, Scrollbar, A11y, Autoplay]}
                                spaceBetween={0}
                                slidesPerView={3}
                                centeredSlides={true}
                                initialSlide={1}
                                loop={true}
                                pagination={{ clickable: true }}
                                autoplay={{ delay: 3000, disableOnInteraction: false }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                            >
                                {
                                    secondTestimonialData?.map((data) => {
                                        return (
                                            <SwiperSlide>
                                                <div class="inner_box bgwhite h-100 p-4 p-md-5">
                                                    <div class="rating"></div>
                                                    <h4>{data.heading}</h4>
                                                    <p style={{fontSize:"18px"}}>{data?.data}</p>
                                                    <div class="author_sec d-flex">
                                                        <div class="img"><img src={data?.image} alt="" height="55" width="55" /></div>
                                                        <div class="px-3">
                                                            <div class="author font-bold">{data?.author}</div>
                                                            <div class="date small">{data?.date}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                    {/* <div class="d-none d-lg-block swiper-button-prev"></div>
                    <div class="d-none d-lg-block swiper-button-next"></div> */}
                </div>
            </div>
            <div class="blog_wrap my-6">
                <div class="container">
                    <div class="">
                        <div class="row mb-4">
                            <div class="col-lg-8">
                                <h2 class="font-bold h2 wow fadeInLeft">Fresh Insights</h2>
                                <div class="small-head font-light wow fadeInRight">Stay abreast of new technology trends with our freshly brewed content.</div>
                            </div>
                            <div class="col-lg-4">
                                <div class="button_sec mt-lg-4 resbtn"><a class="btn btn-primary" href="https://www.technoscore.com/blog" style={{padding:"10px 30px"}}><span>View all Insights</span></a></div>
                            </div>
                        </div>
                        {blogData?.map((data) => {
                            return (
                                <div id={data.id} class="post_block wow fadeInUp">
                                    {/* <div class="post_image hover_effect"><picture><img class="image img-fluid" src={data.img} alt="" height="311" width="470" /></picture></div> */}
                                    <div class="post_block_inner">
                                        <a href="https://www.technoscore.com/blog/how-artificial-intelligence-will-transform-businesses/" class="post_link"></a>
                                        <div class="date small mb-3">{data?.date}</div>
                                        <h4 class="post_title">{data?.title}</h4>
                                        <p style={{fontSize:"18px"}}>{data.text}</p>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div class="button_sec mt-lg-4 text-center mt-2 resbtnMob"><a class="btn btn-primary" href="https://www.technoscore.com/blog"><span>View all Insights</span></a></div>

                </div>
            </div>
            <ContactForm/>
            <Footer/>
        </>
    );
}

export default Home;
