import './App.css';
import Menubar from './Layout/Menubar';
import './assets/css/style.css'
import './assets/css/aboutus.css'
import './assets/css/portfolio.css'
import Home from './Pages/Home';
import Routing from './Routes';

function App() {
  return (
    <Routing/>
  );
}

export default App;
