import React from 'react'
import facebook from '../assets/img/facebook.svg'
import twitter from '../assets/img/twitter.svg'
import linkedin from '../assets/img/linkedin.svg'
import pinterest from '../assets/img/pinterest.svg'
import clutch from '../assets/img/clutch.png'
import top from '../assets/img/gototop.svg'
function Footer() {
    return (
        <div className="footer py-6"  style={{fontSize:"18px"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 py-lg-5">
                        <div className="row">
                            <ul className="linkstyle list-unstyled col wow fadeInUp" data-wow-delay="0.1s">
                                <li className="mb-2"><a href="aboutus.html">About Us</a></li>
                                <li className="mb-2"><a href="services.html">Services</a></li>
                                <li className="mb-2"><a href="technologies.html">Technologies</a></li>
                                <li className="mb-2"><a href="dedicated-team-engagement.html">Hire Resources</a></li>
                                <li className="mb-2"><a href="services.html">Solutions</a></li>
                                <li className="mb-2"><a href="industries.html">Industries</a></li>
                            </ul>
                            <ul className="linkstyle list-unstyled col wow fadeInUp" data-wow-delay="0.3s">
                                <li className="mb-2"><a href="products.html">Case Studies</a></li>
                                <li className="mb-2"><a href="faq.html">FAQ</a></li>
                                <li className="mb-2"><a href="https://www.technoscore.com/blog/">Blog</a></li>
                                <li className="mb-2"><a href="news.html">News</a></li>
                                <li className="mb-2"><a href="contactus.html">Contact Us</a></li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-lg-4 pt-3 py-lg-5 wow fadeInUp" data-wow-delay="0.5s">
                        <address className="pb-lg-4">Floor 3, Vardhman Times Plaza,<br /> Plot 13, DDA Community Centre,Road 44,<br/> Pitampura, New Delhi - 110 034, India</address>
                        <ul className="contact_list list-inline">
                            <li className="phone mb-3 linkstyle"><a className="d-inline-block" href="tel:+91-888-269-6096">+91 888 269 6096</a>, <a className="d-inline-block" href="tel:+1-201-6033633">+1-201-6033633</a>, <a className="d-inline-block" href="tel:+91-11-4264-4425">+91 11 4264 4425</a></li>
                            <li className="mail"><a href="mailto:info@technoscore.com">info@technoscore.com</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 pt-3 py-lg-5 d-flex align-items-start justify-content-between wow fadeInUp" data-wow-delay="0.7s">
                        <ul className="social_link list-inline d-inline-block">
                            <li className="list-inline-item mb-2"><a target="_blank" href="https://www.facebook.com/technoscore"><img src={facebook} alt="" height="" width="" /></a></li>
                            <li className="list-inline-item mb-2"><a target="_blank" href="https://twitter.com/i/flow/login?redirect_after_login=%2FTechnoScore"><img src={twitter} alt="" height="" width="" /></a></li>
                            <li className="list-inline-item mb-2"><a target="_blank" href="https://www.linkedin.com/company/technoscore/"><img src={linkedin} alt="" height="" width="" /></a></li>
                            <li className="list-inline-item mb-2"><a target="_blank" href="https://in.pinterest.com/TechnoScoreCorporateSolution/"><img src={pinterest} alt="" height="" width="" /></a></li>
                            <li className="list-inline-item mb-2"><a target="_blank" href="https://clutch.co/profile/technoscore#summary"><img src={clutch} alt="" height="" width="" /></a></li>
                        </ul>
                        <div className="goto_top d-flex align-items-center"><span className="ttitle me-2 font-bold">TOP</span><span className="arrow_up"><img src={top} alt="" height="" width="" /></span></div>
                    </div>
                </div>
                <div className="row ftr_btm">
                    <div className="col-xl-8 col-lg-8 py-3 wow fadeInLeft" data-wow-delay="0.1s">Copyright 2023, All rights reserved with technoscore.com - Web & Mobile App Development Division of SunTec India.</div>
                    <div className="colcol-xl-3 col-lg-4 py-3 wow fadeInRight" data-wow-delay="0.3s">
                        <ul className="linkstyle list-inline m-0">
                            <li className="list-inline-item me-0"><a href="sitemap.html">Sitemap</a></li>
                            <li className="list-inline-item ms-4 me-0"><a href="iso-certification.html">ISO 9001</a></li>
                            <li className="list-inline-item ms-4 me-0"><a href="iso-information-security-management-system.html">ISO 27001</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer