import React from 'react'
import facebook from '../assets/img/facebook.svg'
import twitter from '../assets/img/twitter.svg'
import linkedin from '../assets/img/linkedin.svg'
import pinterest from '../assets/img/pinterest.svg'

function ContactForm() {
    return (
        <div className="form_sec py-6">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 p-4 px-4 p-lg-5 form_cont wow fadeInLeft">
                        <div className="inner_box position-relative">
                            <h3 className="f30 mt-lg-5 clrwhite font-medium">Looking for Digital Superpowers?</h3>
                            <h2 className="  clrwhite">Send us your Biggest Challenge</h2>
                            <ul className="contact_list list-inline my-lg-5">
                                <li className="mail mb-3 linkstyle"><a href="mailto:info@technoscore.com"  style={{fontSize:"18px"}}>info@technoscore.com</a></li>
                                <li className="phone linkstyle"><a href="tel:+91-888-269-6096"  style={{fontSize:"18px"}}>+91 888 269 6096</a></li>
                            </ul>
                            <ul className="social_link list-inline mb-lg-5">
                                <li className="d-inline-block ml-3"><a target="_blank" href="https://www.facebook.com/technoscore"><img src={facebook} alt="" height="" width=""/></a></li>
                                <li className="d-inline-block"><a target="_blank" href="https://twitter.com/TechnoScore"><img src={twitter} alt="" height="" width=""/></a></li>
                                <li className="d-inline-block"><a target="_blank" href="https://www.linkedin.com/company/technoscore/"><img src={linkedin} alt="" height="" width=""/></a></li>
                                <li className="d-inline-block"><a target="_blank" href="https://in.pinterest.com/TechnoScoreCorporateSolution/"><img src={pinterest } alt="" height="" width=""/></a></li>
                            </ul>
                        </div>
                        <div className="circle_s wow slideInRight" data-wow-delay="0.1s"></div>
                        <div className="circle_l wow slideInRight" data-wow-delay="0.3s"></div>
                    </div>
                    <div className="col-lg-7 p-4 px-4 p-lg-5 wow fadeInRight">
                        <form className="px-lg-3">
                            <div className="form-row row">
                                <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                    <label>Full Name</label>
                                    <input type="text" className="form-control" id="" placeholder="Full Name"/>
                                </div>
                                <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                    <label>Email</label>
                                    <input type="email" className="form-control" id="" placeholder="Type your email"/>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                    <label>Phone</label>
                                    <input type="tel" className="form-control" id="" placeholder="Enter phone number"/>
                                </div>
                                <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                    <label>Organisation</label>
                                    <input type="email" className="form-control" id="" placeholder="Your organisation name"/>
                                </div>
                            </div>
                            <div className="form-group mb-lg-4 mb-3">
                                <div className="col mb-lg-4">
                                    <label>Select the Service you are looking for</label>
                                    <div className="form-group d-flex flex-wrap mt-3">
                                        <label className="checkbox-item me-3 mb-3 font-regular  ml-2" >Web Design
                                            <input type="radio" name="radio" checked/>
                                                <span className="checkmark"></span>
                                        </label>
                                        <label className="checkbox-item me-3 mb-3 font-regular ml-2" >Web Development
                                            <input type="radio" name="radio"/>
                                                <span className="checkmark"></span>
                                        </label>
                                        <label className="checkbox-item me-3 mb-3 font-regular ml-2" >Mobile
                                            Application
                                            <input type="radio" name="radio"/>
                                                <span className="checkmark"></span>
                                        </label>
                                        <label className="checkbox-item me-3 mb-3 font-regular  ml-2" >Mobile
                                            Other
                                            <input type="radio" name="radio"/>
                                                <span className="checkmark"></span>
                                        </label>


                                    </div>
                                </div>
                            </div>
                            <div className="col form-group mb-lg-4 mb-3">
                                <label>Message</label>
                                <textarea className="form-control form-control-lg" id="" rows="3"></textarea>
                            </div>
                            <div className="col form-group mb-lg-4 mb-3">
                                <input style={{padding:"13px 33px"}} id="" type="button" name="" className="formButton btn btn-primary" value="Submit"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm