import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, Col, NavItem, Container, Button } from 'react-bootstrap';
import technologies from '../assets/img/menu-technologies.png'
import resource from '../assets/img/menu-hireResource.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import logo_big from '../assets/img/logo_blue.svg'
import logo_small from '../assets/img/logo_shape.svg'
import Headroom from 'react-headroom';
function Menubar() {
    const [dropdownHover, setDropdownHover] = useState({});

    const handleMouseEnter = (dropdownId) => {
        setDropdownHover((prev) => ({
            ...prev,
            [dropdownId]: true,
        }));
    };

    const handleMouseLeave = (dropdownId) => {
        setDropdownHover((prev) => ({
            ...prev,
            [dropdownId]: false,
        }));
    };
    return (
        <Headroom id="header" className="header header--fixed hide-from-print" role="banner">
            <Container fluid className="container-fluid py-3 py-xl-0">
                <div className="row align-items-xl-center">
                    <div className="col logo_sec pl-4">
                        <a className="logo" href="/">
                            <span className="d-block"><img className="logo_big" src={logo_big} alt="" height="" width="" /></span>
                            <span className="d-block"><img className="logo_small" src={logo_small} alt="" height="395" width="359" /></span>
                        </a>
                    </div>
                    <div className="col menu_sec">
                        {/* <nav className="navbar navbar-expand-xl p-0 position-static">
                            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
                                <div className="hamburger-toggle">
                                    <div className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </button>
                            <div className="collapse navbar-collapse justify-content-end" id="navbar-content">
                                <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                                    <li className="nav-item dropdown dropdown-mega position-static">
                                        <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Company</a>
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside"><span>Arrow</span></div>
                                        <div className="dropdown-menu">
                                            <div className="mega-content container">
                                                <div className="row row-cols-xl-3 py-4">
                                                    <div className="col-12 pb-3 py-xl-5">
                                                        <a className="block_link" href="aboutus.html">
                                                            <div className="font-bold menu_head menu-about">About Technoscore</div>
                                                            <p className="cont">We are a mobile and web development company offering full-cycle web & app development services to diverse enterprises across various industry domains.</p>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 pb-3 py-xl-5">
                                                        <a className="block_link" href="career.html">
                                                            <div className="font-bold menu_head menu-hiring">We are Hiring</div>
                                                            <p className="cont">Be a part of one of the leading web agencies in India and take your career into a whole new dimension.</p>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 py-xl-5">
                                                        <a className="block_link" href="https://www.technoscore.com/blog">
                                                            <div className="font-bold menu_head menu-blog">Blogs</div>
                                                            <p className="cont">Stay abreast of new technology trends with our freshly brewed content.</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown dropdown-mega position-static">
                                        <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Services</a>
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside"><span>Arrow</span></div>
                                        <div className="dropdown-menu">
                                            <div className="mega-content container">
                                                <div className="py-xl-5">
                                                    <div className="row row-cols-xl-3 py-4">
                                                        <div className="col-12 col-xl-3 col-xxl-3">
                                                            <div className="font-bold menu_head ps-0">Services</div>
                                                            <p>TechnoScore provides quality web design and development focused on immediate ROI and achieving your key business objectives.</p>
                                                            <p><a className="link font-bold" href="services.html">Read More</a></p>
                                                        </div>
                                                        <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                            <div className="row row-cols-sm-1 row-cols-xl-3">
                                                                <div className="col py-lg-3"><div className="menu_head menu-webdev2"><a className="font-bold block_link" href="web-development-services.html">Web Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-mobileapp2"><a className="font-bold block_link" href="mobile-app-development-services.html">Mobile Apps Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-ecomdev2"><a className="font-bold block_link" href="ecommerce-web-development-services.html">eCommerce Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-cmsdev2"><a className="font-bold block_link" href="cms-web-development-services.html">CMS Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-blockchain"><a className="font-bold block_link" href="blockchain-development-services.html">Blockchain Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-iot"><a className="font-bold block_link" href="internet-of-things-development.html">Internet of Things (IoT)</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-fullstack2"><a className="font-bold block_link" href="full-stack-development-services.html">Full Stack Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-software"><a className="font-bold block_link" href="custom-software-development-services.html">Custom Software Development</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-digital"><a className="font-bold block_link" href="digital-transformation-services.html">Digital Transformation</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-cloudservice2"><a className="font-bold block_link" href="cloud-managed-services.html">Cloud Managed Services</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-team"><a className="font-bold block_link" href="dedicated-team-engagement.html">Dedicated Team Engagement</a></div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                    <li className="nav-item dropdown dropdown-mega position-static">
                                        <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Technologies</a>
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside"><span>Arrow</span></div>
                                        <div className="dropdown-menu">
                                            <div className="mega-content container">
                                                <div className="row py-4">
                                                    <div className="col-12 col-xl-3 col-xxl-3">
                                                        <div className="font-bold menu_head ps-0">Technologies</div>
                                                        <p>At TechnoScore, every client requirement is executed to perfection through adequate support from a wide range of platforms, languages, frameworks, approaches, tools, and database systems.</p>
                                                        <p><a className="link font-bold" href="technologies.html">Read More</a></p>
                                                        <div className="mt-lg-5 mb-4"><img className="img-fluid" src="assets/img/menu-technologies.png" alt="" height="334" width="366" /></div>
                                                    </div>
                                                    <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                        <div className="row row-cols-xl-3">
                                                            <div className="col-12">
                                                                <div className="menu_head menu-webdev"><a className="font-bold block_link" href="web-development-services.html">Website Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="php-web-development-services.html">PHP Web Development</a>
                                                                    <a className="list-group-item" href="mean-stack-development-services.html">MEAN Stack Development</a>
                                                                    <a className="list-group-item" href="mern-stack-development-services.html">MERN Stack Development</a>
                                                                    <a className="list-group-item" href="laravel-development-services.html">Laravel Development Services</a>
                                                                    <a className="list-group-item" href="cakephp-development-services.html">CakePHP Development</a>
                                                                    <a className="list-group-item" href="nodejs-development-services.html">NodeJS Development Services</a>
                                                                    <a className="list-group-item" href="codeigniter-development-services.html">CodeIgniter Development Services</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-mobileapp"><a className="font-bold block_link" href="mobile-app-development-services.html">Mobile App Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="android-app-development-services.html">Android App Development</a>
                                                                    <a className="list-group-item" href="ios-application-development-services.html">iPhone App Development</a>
                                                                    <a className="list-group-item" href="xamarin-app-development.html">Xamarin App Development</a>
                                                                    <a className="list-group-item" href="react-native-app-development.html">React Native App Development</a>
                                                                    <a className="list-group-item" href="cross-platform-app-development.html">Cross Platform Mobile App</a>
                                                                    <a className="list-group-item" href="flutter-app-development.html">Flutter App Development</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-ecomdev"><a className="font-bold block_link" href="ecommerce-web-development-services.html">eCommerce Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="magento-development-services.html">Magento Development</a>
                                                                    <a className="list-group-item" href="shopify-development-services.html">Shopify Development</a>
                                                                    <a className="list-group-item" href="opencart-development-services.html">OpenCart Development</a>
                                                                    <a className="list-group-item" href="woocommerce-development-services.html">WooCommerce Development</a>
                                                                    <a className="list-group-item" href="nopcommerce-development-services.html">nopCommerce Development</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row row-cols-xl-3">
                                                            <div className="col-12">
                                                                <div className="menu_head menu-fullstack"><a className="font-bold block_link" href="full-stack-development-services.html">Full Stack Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="html5-development-services.html">HTML5</a>
                                                                    <a className="list-group-item" href="angular-js-development-company.html">Angular JS</a>
                                                                    <a className="list-group-item" href="reactjs-development-services.html">React JS</a>
                                                                    <a className="list-group-item" href="vue-development-services.html">Vue JS</a>
                                                                    <a className="list-group-item" href="progressive-web-app-development-services.html">PWA</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-cmsdev"><a className="font-bold block_link" href="cms-web-development-services.html">CMS Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="wordpress-development-services.html">WordPress Web Development</a>
                                                                    <a className="list-group-item" href="drupal-development-customization-services.html">Drupal</a>
                                                                    <a className="list-group-item" href="kentico-cms-development-services.html">Kentico CMS</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-cloudservice"><a className="font-bold block_link" href="cloud-managed-services.html">Cloud Services</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="amazon-web-services-consulting-services.html">Amazon Web Services</a>
                                                                    <a className="list-group-item" href="windows-azure-cloud-computing-services.html">Microsoft Azure</a>
                                                                    <a className="list-group-item" href="google-cloud-application-development-services.html">Google Cloud</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row row-cols-xl-3">
                                                            <div className="col-12">
                                                                <div className="menu_head menu-designtoweb"><a className="font-bold block_link" href="#">Design to Web</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="adobe-xd-to-html-conversion-services.html">Adobe XD to HTML</a>
                                                                    <a className="list-group-item" href="swf-flash-to-html5-conversion-services.html">Flash to HTML5</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-techtrends"><a className="font-bold block_link" href="#">Tech Trends</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="blockchain-development-services.html">Blockchain</a>
                                                                    <a className="list-group-item" href="iot-development-services.html">IoT</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-microsoft"><a className="font-bold block_link" href="#">Microsoft Solutions</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="dotnet-development-services.html">.Net Application Development</a>
                                                                    <a className="list-group-item" href="asp-dotnet-core-development.html">ASP.Net Core Development</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown dropdown-mega position-static">
                                        <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Hire Resource</a>
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside"><span>Arrow</span></div>
                                        <div className="dropdown-menu">
                                            <div className="mega-content container">
                                                <div className="row py-4">
                                                    <div className="col-12 col-xl-3 col-xxl-3">
                                                        <div className="font-bold menu_head ps-0">Hire Resource</div>
                                                        <p>At TechnoScore, we have a resource pool of skilled software development experts who have Hands-On Experience of working on projects spanning diverse industry verticals.</p>
                                                        <p><a className="link font-bold" href="dedicated-team-engagement.html">Read More</a></p>
                                                        <div className="mt-lg-5 mb-4"><img className="img-fluid" src="assets/img/menu-hireResource.png" alt="" height="334" width="366" /></div>
                                                    </div>
                                                    <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                        <div className="row row-cols-sm-1 row-cols-xl-3">
                                                            <div className="col-12">
                                                                <div className="menu_head menu-webdev2"><a className="font-bold block_link" href="hire-web-developers.html">Hire Web Developer</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-php-developers.html">Hire PHP Developers</a>
                                                                    <a className="list-group-item" href="hire-mean-stack-developers.html">Hire MEAN Stack Developers</a>
                                                                    <a className="list-group-item" href="hire-mern-stack-developers.html">Hire MERN Stack Developers</a>
                                                                    <a className="list-group-item" href="hire-laravel-developers.html">Hire Laravel Developers</a>
                                                                    <a className="list-group-item" href="hire-cakephp-developers.html">Hire CakePHP Developers</a>
                                                                    <a className="list-group-item" href="hire-nodejs-developers.html">Hire NodeJS Developers</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-mobileapp2"><a className="font-bold block_link" href="hire-mobile-app-developers.html">Hire Mobile App Developer</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-android-app-developers.html">Hire Android App Developers</a>
                                                                    <a className="list-group-item" href="hire-ios-app-developers.html">Hire iPhone App Developers</a>
                                                                    <a className="list-group-item" href="hire-react-native-developers.html">Hire React Native App Developers</a>
                                                                    <a className="list-group-item" href="hire-flutter-app-developers.html">Hire Flutter App Developers</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-ecomdev2"><a className="font-bold block_link" href="hire-ecommerce-developers.html">Hire eCommerce Developers</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-magento-developers.html">Hire Magento Developers</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row row-cols-xl-3">
                                                            <div className="col-12">
                                                                <div className="menu_head menu-fullstack2"><a className="font-bold block_link" href="hire-full-stack-developers.html">Hire Full Stack Developers</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-html5-developers.html">Hire HTML5 Developers</a>
                                                                    <a className="list-group-item" href="hire-angularjs-developers.html">Hire Angular JS Developers</a>
                                                                    <a className="list-group-item" href="hire-reactjs-developers.html">Hire React JS Developers</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-cmsdev2"><a className="font-bold block_link" href="hire-cms-developers.html">Hire CMS Developers</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-wordpress-developers.html">Hire WordPress Developers</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="menu_head menu-microsoft"><a className="font-bold block_link" href="#">Microsoft Solutions</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-dotnet-developers.html">Hire .Net Application Developers</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown dropdown-mega position-static">
                                        <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Solutions</a>
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside"><span>Arrow</span></div>
                                        <div className="dropdown-menu">
                                            <div className="mega-content container">
                                                <div className="py-xl-5">
                                                    <div className="row row-cols-xl-3 py-4">
                                                        <div className="col-12 col-xl-3 col-xxl-3">
                                                            <div className="font-bold menu_head ps-0">Solutions</div>
                                                            <p>TechnoScore provides quality web design and development focused on immediate ROI and achieving your key business objectives.</p>
                                                            <p><a className="link font-bold" href="#">Read More</a></p>
                                                        </div>
                                                        <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                            <div className="row row-cols-sm-1 row-cols-xl-3">
                                                                <div className="col py-lg-3"><div className="menu_head menu-ecommerceMarket"><a className="font-bold block_link" href="ecommerce-business-solutions.html">eCommerce Marketplace</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-food"><a className="font-bold block_link" href="online-food-ordering-delivery-solutions.html">Food Ordering & Delivery</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-elearning"><a className="font-bold block_link" href="on-demand-elearning-solutions.html">eLearning Management</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head menu-medical"><a className="font-bold block_link" href="on-demand-medical-healthcare-solutions.html">Medical & Healthcare</a></div></div>
                                                                <div className="col py-lg-3"><div className="menu_head  menu-taxi"><a className="font-bold block_link" href="online-taxi-cab-booking-solutions.html">Taxi & Cab Booking</a></div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" href="industries.html">Industries</a></li>
                                    <li className="nav-item"><a className="nav-link" href="portfolio.html">Work</a></li>
                                    <li className="nav-item last"><div className="nav-link py-xl-4 py-lg-3 px-0"><a className="btn btn-primary arrow" href="contactus.html"><span>Contact Us</span></a></div></li>
                                </ul>
                            </div>
                        </nav> */}
                        <Navbar expand="xl" className="p-0 position-static">
                            <Navbar.Toggle aria-controls="navbar-content" />
                            <Navbar.Collapse id="navbar-content" className="justify-content-end">
                                <Nav className="mr-2 mb-2 mb-lg-0">
                                    <NavDropdown show={dropdownHover['dropdown1']}
                                        onMouseEnter={() => handleMouseEnter('dropdown1')}
                                        onMouseLeave={() => handleMouseLeave('dropdown1')}
                                        className='position-static dropdown-mega' title={<span className="dropdown-arrow" style={{ display: "flex" }}>
                                            Company
                                            <KeyboardArrowDownIcon />
                                        </span>} id="company-dropdown">

                                        {/* Dropdown content */}
                                        <NavDropdown.Item href="aboutus.html">
                                            <div className="mega-content container">
                                                <div className="row row-cols-xl-3 py-4">
                                                    <div className="col-4 pb-3 py-xl-5">
                                                        <a className="block_link" href="aboutus.html">
                                                            <div className="font-bold menu_head menu-about">About Technoscore</div>
                                                            <p className="cont">We are a mobile and web development company offering full-cycle web & app development services to diverse enterprises across various industry domains.</p>
                                                        </a>
                                                    </div>
                                                    <div className="col-4 pb-3 py-xl-5">
                                                        <a className="block_link" href="career.html">
                                                            <div className="font-bold menu_head menu-hiring">We are Hiring</div>
                                                            <p className="cont">Be a part of one of the leading web agencies in India and take your career into a whole new dimension.</p>
                                                        </a>
                                                    </div>
                                                    <div className="col-4 py-xl-5">
                                                        <a className="block_link" href="https://www.technoscore.com/blog">
                                                            <div className="font-bold menu_head menu-blog">Blogs</div>
                                                            <p className="cont">Stay abreast of new technology trends with our freshly brewed content.</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown show={dropdownHover['dropdown2']}
                                        onMouseEnter={() => handleMouseEnter('dropdown2')}
                                        onMouseLeave={() => handleMouseLeave('dropdown2')} className='position-static dropdown-mega' title={<span className="dropdown-arrow" style={{ display: "flex" }}>
                                            Services
                                            <KeyboardArrowDownIcon />
                                        </span>} id="company-dropdown">
                                        {/* Dropdown content */}
                                        <NavDropdown.Item href="aboutus.html">
                                            <div className="mega-content container">
                                                <div className="py-xl-5">
                                                    <div className="row row-cols-xl-3 py-4">
                                                        <div className="col-12 col-xl-3 col-xxl-3">
                                                            <div className="font-bold menu_head ps-0 p-0">Services</div>
                                                            <p style={{ textWrap: 'wrap' }} className='cont p-0'>TechnoScore provides quality web design and development focused on immediate ROI and achieving your key business objectives.</p>
                                                            <p><a className="link font-bold" href="services.html">Read More</a></p>
                                                        </div>
                                                        <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                            <div className="row row-cols-sm-1 row-cols-xl-3">
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-webdev2"><a className="font-bold block_link" href="web-development-services.html">Web Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-mobileapp2"><a className="font-bold block_link" href="mobile-app-development-services.html">Mobile Apps Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-ecomdev2"><a className="font-bold block_link" href="ecommerce-web-development-services.html">eCommerce Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-cmsdev2"><a className="font-bold block_link" href="cms-web-development-services.html">CMS Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-blockchain"><a className="font-bold block_link" href="blockchain-development-services.html">Blockchain Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-iot"><a className="font-bold block_link" href="internet-of-things-development.html">Internet of Things (IoT)</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-fullstack2"><a className="font-bold block_link" href="full-stack-development-services.html">Full Stack Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-software"><a className="font-bold block_link" href="custom-software-development-services.html">Custom Software Development</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-digital"><a className="font-bold block_link" href="digital-transformation-services.html">Digital Transformation</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-cloudservice2"><a className="font-bold block_link" href="cloud-managed-services.html">Cloud Managed Services</a></div></Col>
                                                                <Col xl={4} className="col py-lg-3"><div className="menu_head menu-team"><a className="font-bold block_link" href="dedicated-team-engagement.html">Dedicated Team Engagement</a></div></Col>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown show={dropdownHover['dropdown3']}
                                        onMouseEnter={() => handleMouseEnter('dropdown3')}
                                        onMouseLeave={() => handleMouseLeave('dropdown3')} className='position-static dropdown-mega' title={<span className="dropdown-arrow" style={{ display: "flex" }}>
                                            Technologies
                                            <KeyboardArrowDownIcon />
                                        </span>} id="company-dropdown">
                                        {/* Dropdown content */}
                                        <NavDropdown.Item href="aboutus.html">
                                            <div className="mega-content container">
                                                <div className="row py-4">
                                                    <div className="col-12 col-xl-3 col-xxl-3">
                                                        <div className="font-bold menu_head ps-0 p-0">Technologies</div>
                                                        <p style={{ textWrap: 'wrap' }} className='cont p-0'>At TechnoScore, every client requirement is executed to perfection through adequate support from a wide range of platforms, languages, frameworks, approaches, tools, and database systems.</p>
                                                        <p><a className="link font-bold" href="technologies.html">Read More</a></p>
                                                        <div className="mt-lg-5 mb-4"><img className="img-fluid" src={technologies} alt="" height="334" width="366" /></div>
                                                    </div>
                                                    <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                        <div className="row row-cols-xxl-3">
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-webdev"><a className="font-bold block_link" href="web-development-services.html">Website Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="php-web-development-services.html">PHP Web Development</a>
                                                                    <a className="list-group-item" href="mean-stack-development-services.html">MEAN Stack Development</a>
                                                                    <a className="list-group-item" href="mern-stack-development-services.html">MERN Stack Development</a>
                                                                    <a className="list-group-item" href="laravel-development-services.html">Laravel Development Services</a>
                                                                    <a className="list-group-item" href="cakephp-development-services.html">CakePHP Development</a>
                                                                    <a className="list-group-item" href="nodejs-development-services.html">NodeJS Development Services</a>
                                                                    <a className="list-group-item" href="codeigniter-development-services.html">CodeIgniter Development Services</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-mobileapp"><a className="font-bold block_link" href="mobile-app-development-services.html">Mobile App Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="android-app-development-services.html">Android App Development</a>
                                                                    <a className="list-group-item" href="ios-application-development-services.html">iPhone App Development</a>
                                                                    <a className="list-group-item" href="xamarin-app-development.html">Xamarin App Development</a>
                                                                    <a className="list-group-item" href="react-native-app-development.html">React Native App Development</a>
                                                                    <a className="list-group-item" href="cross-platform-app-development.html">Cross Platform Mobile App</a>
                                                                    <a className="list-group-item" href="flutter-app-development.html">Flutter App Development</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-ecomdev"><a className="font-bold block_link" href="ecommerce-web-development-services.html">eCommerce Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="magento-development-services.html">Magento Development</a>
                                                                    <a className="list-group-item" href="shopify-development-services.html">Shopify Development</a>
                                                                    <a className="list-group-item" href="opencart-development-services.html">OpenCart Development</a>
                                                                    <a className="list-group-item" href="woocommerce-development-services.html">WooCommerce Development</a>
                                                                    <a className="list-group-item" href="nopcommerce-development-services.html">nopCommerce Development</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-fullstack"><a className="font-bold block_link" href="full-stack-development-services.html">Full Stack Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="html5-development-services.html">HTML5</a>
                                                                    <a className="list-group-item" href="angular-js-development-company.html">Angular JS</a>
                                                                    <a className="list-group-item" href="reactjs-development-services.html">React JS</a>
                                                                    <a className="list-group-item" href="vue-development-services.html">Vue JS</a>
                                                                    <a className="list-group-item" href="progressive-web-app-development-services.html">PWA</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-cmsdev"><a className="font-bold block_link" href="cms-web-development-services.html">CMS Development</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="wordpress-development-services.html">WordPress Web Development</a>
                                                                    <a className="list-group-item" href="drupal-development-customization-services.html">Drupal</a>
                                                                    <a className="list-group-item" href="kentico-cms-development-services.html">Kentico CMS</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-cloudservice"><a className="font-bold block_link" href="cloud-managed-services.html">Cloud Services</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="amazon-web-services-consulting-services.html">Amazon Web Services</a>
                                                                    <a className="list-group-item" href="windows-azure-cloud-computing-services.html">Microsoft Azure</a>
                                                                    <a className="list-group-item" href="google-cloud-application-development-services.html">Google Cloud</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-designtoweb"><a className="font-bold block_link" href="#">Design to Web</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="adobe-xd-to-html-conversion-services.html">Adobe XD to HTML</a>
                                                                    <a className="list-group-item" href="swf-flash-to-html5-conversion-services.html">Flash to HTML5</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-techtrends"><a className="font-bold block_link" href="#">Tech Trends</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="blockchain-development-services.html">Blockchain</a>
                                                                    <a className="list-group-item" href="iot-development-services.html">IoT</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-microsoft"><a className="font-bold block_link" href="#">Microsoft Solutions</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="dotnet-development-services.html">.Net Application Development</a>
                                                                    <a className="list-group-item" href="asp-dotnet-core-development.html">ASP.Net Core Development</a>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown show={dropdownHover['dropdown4']}
                                        onMouseEnter={() => handleMouseEnter('dropdown4')}
                                        onMouseLeave={() => handleMouseLeave('dropdown4')} className='position-static dropdown-mega' title={<span className="dropdown-arrow" style={{ display: "flex" }}>
                                            Hire Resource
                                            <KeyboardArrowDownIcon />
                                        </span>} id="company-dropdown">
                                        {/* Dropdown content */}
                                        <NavDropdown.Item href="aboutus.html">
                                            <div className="mega-content container">
                                                <div className="row py-4">
                                                    <div className="col-12 col-xl-3 col-xxl-3">
                                                        <div className="font-bold menu_head ps-0 p-0">Hire Resource</div>
                                                        <p style={{ textWrap: 'wrap' }} className='cont p-0'>At TechnoScore, we have a resource pool of skilled software development experts who have Hands-On Experience of working on projects spanning diverse industry verticals.</p>
                                                        <p><a className="link font-bold" href="dedicated-team-engagement.html">Read More</a></p>
                                                        <div className="mt-lg-5 mb-4"><img className="img-fluid" src={resource} alt="" height="334" width="366" /></div>
                                                    </div>
                                                    <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                        <div className="row row-cols-sm-1 row-cols-xl-3">
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-webdev2"><a className="font-bold block_link" href="hire-web-developers.html">Hire Web Developer</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-php-developers.html">Hire PHP Developers</a>
                                                                    <a className="list-group-item" href="hire-mean-stack-developers.html">Hire MEAN Stack Developers</a>
                                                                    <a className="list-group-item" href="hire-mern-stack-developers.html">Hire MERN Stack Developers</a>
                                                                    <a className="list-group-item" href="hire-laravel-developers.html">Hire Laravel Developers</a>
                                                                    <a className="list-group-item" href="hire-cakephp-developers.html">Hire CakePHP Developers</a>
                                                                    <a className="list-group-item" href="hire-nodejs-developers.html">Hire NodeJS Developers</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-mobileapp2"><a className="font-bold block_link" href="hire-mobile-app-developers.html">Hire Mobile App Developer</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-android-app-developers.html">Hire Android App Developers</a>
                                                                    <a className="list-group-item" href="hire-ios-app-developers.html">Hire iPhone App Developers</a>
                                                                    <a className="list-group-item" href="hire-react-native-developers.html">Hire React Native App Developers</a>
                                                                    <a className="list-group-item" href="hire-flutter-app-developers.html">Hire Flutter App Developers</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-ecomdev2"><a className="font-bold block_link" href="hire-ecommerce-developers.html">Hire eCommerce Developers</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-magento-developers.html">Hire Magento Developers</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-fullstack2"><a className="font-bold block_link" href="hire-full-stack-developers.html">Hire Full Stack Developers</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-html5-developers.html">Hire HTML5 Developers</a>
                                                                    <a className="list-group-item" href="hire-angularjs-developers.html">Hire Angular JS Developers</a>
                                                                    <a className="list-group-item" href="hire-reactjs-developers.html">Hire React JS Developers</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-cmsdev2"><a className="font-bold block_link" href="hire-cms-developers.html">Hire CMS Developers</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-wordpress-developers.html">Hire WordPress Developers</a>
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <div className="menu_head menu-microsoft"><a className="font-bold block_link" href="#">Microsoft Solutions</a></div>
                                                                <div className="list-group mb-4 mb-lg-4">
                                                                    <a className="list-group-item" href="hire-dotnet-developers.html">Hire .Net Application Developers</a>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown show={dropdownHover['dropdown5']}
                                        onMouseEnter={() => handleMouseEnter('dropdown5')}
                                        onMouseLeave={() => handleMouseLeave('dropdown5')} className='position-static dropdown-mega' title={<span className="dropdown-arrow" style={{ display: "flex" }}>
                                            Solutions
                                            <KeyboardArrowDownIcon />
                                        </span>} id="company-dropdown">
                                        {/* Dropdown content */}
                                        <NavDropdown.Item href="aboutus.html">
                                            <div className="mega-content container">
                                                <div className="py-xl-5">
                                                    <div className="row row-cols-xl-3 py-4">
                                                        <div className="col-12 col-xl-3 col-xxl-3">
                                                            <div className="font-bold menu_head ps-0 p-0">Solutions</div>
                                                            <p style={{ textWrap: 'wrap' }} className='cont p-0'>TechnoScore provides quality web design and development focused on immediate ROI and achieving your key business objectives.</p>
                                                            <p><a className="link font-bold" href="#">Read More</a></p>
                                                        </div>
                                                        <div className="border-left position-relative col-12 col-xl-8 col-xxl-8 offset-xxl-1">
                                                            <div className="row row-cols-sm-1 row-cols-xl-3">
                                                                <Col xl={4} className='py-lg-3'><div className="menu_head menu-ecommerceMarket"><a className="font-bold block_link" href="ecommerce-business-solutions.html">eCommerce Marketplace</a></div></Col>
                                                                <Col xl={4} className='py-lg-3'><div className="menu_head menu-food"><a className="font-bold block_link" href="online-food-ordering-delivery-solutions.html">Food Ordering & Delivery</a></div></Col>
                                                                <Col xl={4} className='py-lg-3'><div className="menu_head menu-elearning"><a className="font-bold block_link" href="on-demand-elearning-solutions.html">eLearning Management</a></div></Col>
                                                                <Col xl={4} className='py-lg-3'><div className="menu_head menu-medical"><a className="font-bold block_link" href="on-demand-medical-healthcare-solutions.html">Medical & Healthcare</a></div></Col>
                                                                <Col xl={4} className='py-lg-3'><div className="menu_head  menu-taxi"><a className="font-bold block_link" href="online-taxi-cab-booking-solutions.html">Taxi & Cab Booking</a></div></Col>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown className='position-static dropdown-mega' title="Industries" id="company-dropdown"></NavDropdown>
                                    <NavDropdown className='position-static dropdown-mega' title="Work" id="company-dropdown"></NavDropdown>
                                    <NavItem className='last' id="company-dropdown">
                                        <div className="nav-link py-xl-4 py-lg-3 px-0"><a className="btn btn-primary arrow" href="contactus.html"><span>Contact Us</span></a></div>
                                    </NavItem>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>

                    </div>
                </div>
            </Container>
        </Headroom>
    )
}

export default Menubar