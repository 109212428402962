import React, { useEffect } from 'react'
import Menubar from '../Layout/Menubar'
import Footer from '../Layout/Footer'
import india from '../assets/img/india.svg'
import usa from '../assets/img/united-states-of-america.svg'
import uk from '../assets/img/united-kingdom.svg'
import call from '../assets/img/Icon feather-phone-call.svg'
import WOW from "wowjs";
import email from '../assets/img/email.svg'
function ContactUs() {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);
    return (
        <>
            <Menubar />
            <div className="form_sec  py-6 py-lg-6">
                <div className="container">
                    <div className="row      bg-white">
                        <div className="col-lg-4 ">
                            <div className="p-4 px-4 p-lg-5  wow fadeInLeft form_cont">
                                <div className="inner_box position-relative">
                                    <h6 className="f30  clrwhite font-medium f24">Call, email, Meet or Skype us -<span className="brk"></span> however you’d prefer to work we’d <span className="brk"></span>love to hear from you</h6>
                                    <h3 className="f30 my-lg-5 clrwhite font-regular   ">We’d love to hear from you.</h3>
                                    <div className="address-container pb-5">
                                        <figure className="d-flex align-items-center mb-3 m-0">
                                            <img src={india} className="me-3 mr-2" alt="india" height="30" width="30" />
                                            <figcaption className="font-bold">India</figcaption>
                                        </figure>
                                        <div className="address-txt mb-3">
                                            <address className="m-0 font-regular">Floor 3, Vardhman Times Plaza,</address>
                                            <address className="m-0 font-regular"> Plot 13, DDA Community Centre,</address>
                                            <address className="m-0 font-regular"> Road 44, Pitampura, New Delhi - 110034, India</address>
                                        </div>
                                        <ul className="contact_list list-inline ">
                                            <li className=" mb-2  linkstyle p-0"><img src={call} className="me-3 mr-2" alt="phone" height="20" width="20"/> <a href="tel:+91-888-269-6096">+91 888 269 6096</a>,<a href="tel:+91-11-4264-4425">+91 11 4264 4425</a></li>
                                            <li className=" linkstyle p-0"><img src={email} className="me-4 mr-2" alt="email" height="20" width="20"/><a href="mailto:info@technoscore.com">info@technoscore.com</a></li>

                                        </ul>
                                    </div>
                                    <div className="address-container pb-5">
                                        <figure className="d-flex align-items-center mb-3">
                                            <img src={usa} className="me-3 mr-2" alt="india" height="30" width="30" />
                                            <figcaption className="font-bold">USA</figcaption>
                                        </figure>
                                        <div className="address-txt mb-3">
                                            <address className="m-0 font-regular">1968 S. Coast Hwy #499,</address>
                                            <address className="m-0 font-regular"> Laguna Beach CA92651</address>
                                        </div>

                                        <ul className="contact_list list-inline ">
                                            <li className=" linkstyle p-0"><img src={call} className="me-4 mr-2" alt="phone" height="20" width="20"/><a href="tel:+1-646-613-0076">+1 646 613 0076</a></li>
                                        </ul>
                                    </div>
                                    <div className="address-container pb-5">
                                        <figure className="d-flex align-items-center mb-3">
                                            <img src={uk} className="me-3 mr-2" alt="india" height="30" width="30" />
                                            <figcaption className="font-bold">UK</figcaption>
                                        </figure>
                                        <div className="address-txt mb-5">
                                            <address className="m-0 font-regular">86-90 Paul Street,</address>
                                            <address className="m-0 font-regular"> London, EC2A 4NE</address>
                                        </div>

                                    </div>


                                </div>
                                <div className="circle_s wow slideInRight" data-wow-delay="0.1s"></div>
                                <div className="circle_l wow slideInRight" data-wow-delay="0.3s"></div>
                            </div>
                        </div>
                        <div className="col-lg-8 p-4 px-3  ps-lg-5  px-lg-4 px-md-4 p-lg-5 wow fadeInRight">
                            <p className="font-regular clr-darkgrey f24 mb-4">Let us know who you are and what you’re looking for below,<span className="brk"></span> and we’ll get your message to the right Secfi team.</p>
                            <h4 className="font-regular f36 my-5">We’re here to help</h4>
                            <form className="px-lg-3">
                                <div className="form-row row bg-white">
                                    <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                        <label className="mb-2 text-black font-bold">Full Name</label>
                                        <input type="text" name="fname" className="form-control" id="" placeholder="Full Name" required/>
                                    </div>
                                    <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                        <label className="mb-2 text-black font-bold">Email</label>
                                        <input type="email" name="email" className="form-control" id="" placeholder="Type your email" required/>
                                    </div>
                                </div>
                                <div className="form-row bg-white row">
                                    <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                        <label className="mb-2 text-black font-bold">Phone</label>
                                        <input type="tel" name="number" className="form-control" id="" placeholder="Enter phone number" required/>
                                    </div>
                                    <div className="col-sm-6 form-group mb-lg-4 mb-3">
                                        <label className="mb-2 text-black font-bold">Organisation</label>
                                        <input type="email" name="orgemail" className="form-control" id="" placeholder="Your organisation name" required/>
                                    </div>
                                </div>
                                <div className="form-group mb-lg-4 mb-3">
                                    <div className="col mb-lg-4">
                                        <label className="text-black font-bold">Select the Service you are looking for</label>
                                        <div className="form-group d-flex flex-wrap mt-2">

                                            <label className="checkbox-item me-3 mb-3 font-regular mr-3" >Web Design
                                                <input type="radio" name="radio" checked />
                                                    <span className="checkmark"></span>
                                            </label>
                                            <label className="checkbox-item me-3 mb-3 font-regular mr-3" >Web Development
                                                <input type="radio" name="radio" />
                                                    <span className="checkmark"></span>
                                            </label>
                                            <label className="checkbox-item me-3 mb-3 font-regular mr-3" >Mobile
                                                Application
                                                <input type="radio" name="radio" />
                                                    <span className="checkmark"></span>
                                            </label>
                                            <label className="checkbox-item me-3 mb-3 font-regular mr-3" >Mobile
                                                Other
                                                <input type="radio" name="radio" />
                                                    <span className="checkmark"></span>
                                            </label>


                                        </div>
                                    </div>
                                </div>
                                <div className="col form-group mb-lg-4 mb-3">
                                    <label className="mb-2 text-black font-bold">Tell us about your project</label>
                                    <textarea style={{borderRadius:"1rem"}} className="form-control form-control-lg rounded-4" name="message" id="" rows="5" placeholder="Description text about the project"></textarea>
                                </div>
                                <div className="col form-group mb-lg-4 mb-3">
                                    <input style={{padding:"15px 40px"}} id="" type="button" name="" className="formButton btn btn-primary" value="Submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs