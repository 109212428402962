import React, { useEffect, useState } from 'react'
import Menubar from '../Layout/Menubar'
import ContactForm from '../Components/ContactForm'
import Footer from '../Layout/Footer'
import WOW from "wowjs";
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import salford from '../assets/img/salford.png'
import phoenix from '../assets/img/phoenix.png'
import nielsen from '../assets/img/nielsen.png'
import dentsu from '../assets/img/dentsu.png'
import mawady_logo from '../assets/img/mawady_logo.png'
import lease_plan from '../assets/img/lease_plan.png'
import add from '../assets/img/add.png'
import minus_icon from '../assets/img/minus-icon.svg'
import solution from '../assets/img/reliable-solution.svg'
import partnership from '../assets/img/partnership.svg'
import faster from '../assets/img/faster.svg'
import cost_saving from '../assets/img/cost_saving.svg'
import no_hidden_charge from '../assets/img/no_hidden_charge.svg'
import technology from '../assets/img/technology.svg'
import technical_support from '../assets/img/technical_support.svg'
import guaranteed from '../assets/img/guaranteed.svg'
import trust_pilot from '../assets/img/trust-pilot.png'
import clutch_nw from '../assets/img/clutch_nw.png'
import goodfirms from '../assets/img/goodfirms.png'
import aws from '../assets/img/aws.png'
import microsoft from '../assets/img/microsoft.png'
import serving_business from '../assets/img/serving_business.png'

function AboutUs() {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const statsData = [
        {
            val: '20+',
            text: "Years of Experience"
        },
        {
            val: '200+',
            text: "Employee Strength"
        },
        {
            val: '1.5k+',
            text: "Satisfied Global Clients"
        },
        {
            val: '6k+',
            text: "Projects Delivered"
        },
        {
            val: '98%',
            text: "Clients Retention Rate"
        }

    ]
    const valuesData = [
        {
            heading: "Transparent",
            text: "We believe in transparency beyond all else. That's reflected in our process, pricing, and hiring methodology."
        },
        {
            heading: "Excellence",
            text: "Experienced in working for diverse needs, we have delivered over 5500 projects across the globe."
        },
        {
            heading: "Genuine",
            text: "Interview our top IT talent virtually and test their proficiency before you hire them for any project requirements."
        },
        {
            heading: "Dedicated",
            text: "Client satisfaction is our ultimate goal. We strive to deliver custom web development solutions that perfectly cater to unique requirements."
        }
    ]
    const reasonData = [
        {
            text1: 'Reliable',
            text2: 'Solutions',
            img: solution
        },
        {
            text1: 'Partner With',
            text2: 'Certified Experts',
            img: partnership
        },
        {
            text1: 'Assured 2X ',
            text2: 'Faster Results',
            img: faster
        },
        {
            text1: "Up to 70%",
            text2: "Cost Savings",
            img: cost_saving
        },
        {
            text1: "No Hidden",
            text2: "Charges",
            img: no_hidden_charge
        },
        {
            text1: "A Rich",
            text2: "Technology Culture",
            img: technology
        },
        {
            text1: "Round-the-Clock",
            text2: "Technical Support",
            img: technical_support
        },
        {
            text1: "Guaranteed",
            text2: "Transparency",
            img: guaranteed
        }
    ]
    const partners = [
        trust_pilot, clutch_nw, goodfirms, aws, microsoft
    ]
    const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <Menubar />
            <div className="title_sec py-lg-6 py-md-5 pt-0 pb-4">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-5">

                            <div className="small-head wow fadeInLeft l-28">About - Your long-term design partners<span className="brk"></span> helping your business reach its full<span className="brk"></span> potential</div>

                        </div>
                        <div className="col-lg-7 wow fadeInRight" >
                            <h2 className="h2 clrlightgrey anm-heading">At TechnoScore, we unlock technology for businesses. We are a mobile and web development company offering full-cycle web & app development services to diverse enterprises across various industry domains.</h2>
                            <div className="f24 font-light anm-text">Our team dedicates itself to client fulfillment through full-cycle support for ideation, designing, development, deployment, and maintenance.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-2  py-lg-5 gallery-section">
                <div className="container o-hidden">
                    <div className=" row row-cols-1 m-0 row-cols-sm-1 ">
                        <div className="col-md-6  mt-lg-0 p-0" >
                            <div className="image-itm">
                                <div className="w-0 bg-container bg-w1 active">
                                    <div className="img-anm img-an1"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4 p-0 p-lg-3  p-md-3 ps-lg-5" >
                            <div className="image-itm">
                                <div className="mt-3 bg-container bg-w2  m-0 active">
                                    <div className="img-anm img-an2"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stats_sec py-6">
                <div className="container">
                    <Row className="row row-cols-2 row-cols-sm-2 row-cols-md-3 justify-content-center">
                        {
                            statsData?.map((x) => {
                                return (
                                    <Col xl={4} sm={12} className="col stats_box text-center mb-5">
                                        <span className="stats_value wow fadeInUp" data-wow-delay="0.1s">{x.val}</span>
                                        <span className="d-block wow fadeInUp mt-2" data-wow-delay="0.7s">{x.text}</span>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
            <div className="gray-0  py-6 mt-0">
                <div className="container">
                    <div className="row">
                        <div className=" col-12 col-lg-6  wow fadeInLeft" data-wow-delay="0.5s">
                            <div className="col-lg-10  col-12  ">
                                <div className="f60 mb-5 mb-lg-0 mb-md-0 l-70 text-black">Driving Innovation for Enterprises through Web & Mobile App Solutions</div>
                            </div>
                        </div>
                        <div className="col  wow fadeInRight" data-wow-delay="0.5s">
                            <h2 className="h2 font-medium mb-3">Mission</h2>
                            <p className="f24 font-light mb-lg-5 mb-5">We dwell on the 5 Ds [Discover, Define, Design, Develop, & Deliver] to provide high-quality solutions and help our clients make a difference in their target markets.</p>
                            <h2 className="h2 font-medium mb-3">Vision</h2>
                            <p className="f24 font-light">The success of our clients is closely related to our self-fulfillment goals and growth plans. We aim to make stellar technological solutions a feasible and affordable enterprise goal.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clients_sec py-6">
                <div className="container">
                    <div className="text-center small-head wow fadeInLeft">Trusted by the world's most innovative companies</div>
                    <div className="row row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-lg-6 g-lg-5 pt-lg-5 wow fadeInRight">
                        <div className="col"><picture><img className="img-fluid" src={salford} alt="" height="130" width="207" /></picture></div>
                        <div className="col"><picture><img className="img-fluid" src={phoenix} alt="" height="130" width="207" /></picture></div>
                        <div className="col"><picture><img className="img-fluid" src={nielsen} alt="" height="130" width="207" /></picture></div>
                        <div className="col"><picture><img className="img-fluid" src={dentsu} alt="" height="130" width="207" /></picture></div>
                        <div className="col"><picture><img className="img-fluid" src={mawady_logo} alt="" height="130" width="207" /></picture></div>
                        <div className="col"><picture><img className="img-fluid" src={lease_plan} alt="" height="130" width="207" /></picture></div>
                    </div>
                </div>
            </div>
            <div className="parallex_sec about_img"></div>
            <div className="py-6 core_values_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 wow fadeInLeft" data-wow-delay="0.7s">
                            <div className="col-lg-9">
                                <div className="small-head">Our Core Values</div>
                                <h3 className="font-regular clrlightgrey f36 mt-5">At TechnoScore, we strive to provide full-cycle mobile, web, and software design and development services in a quick turnaround time.</h3>
                                <div className="f24 font-light  mt-5">Our solutions have become the hallmark of affordable & functional excellence in the niche of custom enterprise development. Our drive to achieve continuous growth for clients is the fuel that inspires innovation in what we do.</div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-5 wow fadeInRight" data-wow-delay="0.7s">
                            <Accordion defaultActiveKey={0} id="accordionExample">
                                {
                                    valuesData?.map((data, index) => {
                                        return (
                                            <Accordion.Item className="accordion-item py-2" eventKey={index}>
                                                <Accordion.Header className="accordion-header" onClick={() => toggleAccordion(index)}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <span className="f24 orng-clr">{`0${index + 1}`}</span><h5 className="m-0 ms-4 f36 font-medium ml-4">{data?.heading}</h5>
                                                    </div>
                                                    <Button variant="link">
                                                        {openAccordionIndex === index ? <img src={minus_icon} width={'20px'} /> : <img src={add} width={'20px'} />}
                                                    </Button>
                                                </Accordion.Header>
                                                <Accordion.Body id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample" style={{ width: "90%" }}>
                                                    <div className="accordion-body" style={{ fontSize: "18px", paddingBottom: "20px" }}>{data.text}</div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gray-300 py-6">
                <div className="container">
                    <div className="text-center col-lg-8 m-auto">
                        <div className="small-head">Why Choose Us</div>
                        <p>Our refined and technically sound web & app development services allow businesses to meet their evolving needs and target wider audiences while bringing agility to their operational processes.</p>
                    </div>
                    <Row className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-3 mt-lg-5" >
                        {
                            reasonData?.map((x) => {
                                return (
                                    <Col xl={3} sm={6} className="col-6 pb-4 pb-lg-0 pb-md-0 col-sm-6 d-md-flex text-center text-md-start mt-lg-5 mt-4 wow  fadeInUp p-0" data-wow-delay="0.7s" style={{ display: "flex" }}>
                                        <div className="me-lg-3 me-md-3 "><img className="mb-3 h-40 " src={x.img} alt="" height="" width="" /></div>
                                        <div className="font-medium h6 ml-3">{x.text1} <span className="d-block">{x.text2}</span></div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
            <div className="py-6  co-partner-section wow  fadeInUp" data-wow-delay="0.7s">
                <div className="container">
                    <div className="text-center col-lg-8  co-partner-container m-auto pb-lg-5  pb-md-5  pr-0 p">
                        <div className="small-head">Partners, Awards & Recognition</div>
                    </div>

                    <Row className="row clients_sec  row-cols-3 justify-content-center py-6 justify-content-md-normal  justify-content-lg-normal row-cols-lg-5 row-cols-md-5 row-cols-sm-3  ">
                        {
                            partners?.map((x) => {
                                return (
                                    <Col style={{ width: "20%" }} className="col firmicons py-md-6  py-lg-6  py-sm-0  px-3">
                                        <div className="circle"></div>
                                        <img src={x} style={{ padding: "20px 10px" }} alt="" height="93" width="262" />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
            <div class="dark-0 clrwhite py-6 serving_business">
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-12 col-lg-5 wow  fadeInLeft " data-wow-delay="0.7s">
                            <div class="small-head mb-5 text-white font-medium">Serving Businesses Beyond Boundaries</div>
                            <h3 class="clrwhite font-regular l-32">The TechnoScore team has delivered 5500+ projects to clients across more than 45 countries, including the US, UK, Middle East, Europe, and Asia-pacific countries. Our custom web & app development services are trusted by businesses across the globe, including Fortune 500 companies.</h3>
                        </div>
                        <div class="col col-lg-7 position-relative business_box wow  fadeInRight mb-5" data-wow-delay="0.7s">
                            <img class="img-fluid" src={serving_business} alt="" height="523" width="950" />
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((x) => {
                                    return (
                                        <span class={`blink1 dot_${x}`}>
                                            <div class="circle1"></div>
                                            <div class="circle2"></div>
                                            <div class="circle3"></div>
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div >
            <ContactForm />
            <Footer />
        </>
    )
}

export default AboutUs