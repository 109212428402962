import React, { useEffect } from 'react'
import WOW from "wowjs";
import Menubar from '../Layout/Menubar';
import Footer from '../Layout/Footer';

function Portfolio() {
    useEffect(() => {
        const wow = new WOW.WOW({
            animateClass: 'animated',
            offset: 100,
            callback: function (box) {
                const parentFigure = box.parentNode.closest("figure");
                if (parentFigure) {
                    const bgContainer = parentFigure.querySelector(".bg-container");
                    if (bgContainer) {
                        bgContainer.classList.add("active");
                        console.log(`WOW: animating <${box.tagName.toLowerCase()}>`);
                    }
                }
            }
        });
        wow.init({});
    }, []);
    return (
        <>
            <Menubar />
            <div className="title_sec">
                <div className="container">
                    <div className="row pt-lg-5 p-0 ">
                        <div className="col-lg-5">

                            <div className="small-head wow fadeInLeft l-28" data-wow-delay="0.6s">Explore  <span className="brk"></span> Our    Portfolio

                            </div>
                        </div>
                        <div className="col-lg-7 wow fadeInRight" data-wow-delay="0.9s">
                            <h2 className="h2 clrlightgrey">We work to design a sleek and modern website that perfectly reflects our client's brand identity.</h2>
                            <div className="f24 font-light">Post your project to receive free consultancy and explore our talent pool of experienced in-house programmers, hiring only the best and most suitable resources for your requirements with easy payment mode.</div>
                        </div>
                    </div>
                </div>
            </div >
            <section className="portfolio-section py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 pe-lg-5 mb-5 mb-lg-0 mb-md-0">
                            <figure>
                                <div className="image-item mb-4">
                                    <div className="w-0 bg-container bg-right-overlay loadImg" >
                                        <div className="img-anim portfolio-img1"></div>
                                    </div>
                                </div>
                                <figcaption className=" wow fadeInUp" data-wow-delay="0.9s">
                                    <h5 className="f36">Jaquar  </h5>
                                    <div className="col-lg-8"> <p className="font-regular">A feature-packed app by the renowned brand Jaguar that lets users find dealers, place orders and request custom products or simply ask for quotes.</p>
                                    </div></figcaption>

                            </figure>

                        </div>
                        <div className="col-md-6 pe-lg-5 mb-5 mb-lg-0 mb-md-0 mt-6">
                            <figure>
                                <div className="image-item mb-4">
                                    <div className="w-0 bg-container bg-left-overlay loadImg">
                                        <div className="img-anim portfolio-img2"></div>
                                    </div>
                                </div>
                                <figcaption className=" wow fadeInUp" data-wow-delay="0.9s">
                                    <h5 className="f36">Xpert Drivers  </h5>
                                    <div className="col-lg-8"> <p className="font-regular">A platform that offers driving lessons for a range of vehicles while imparting knowledge around diverse areas such as technicalities related to safety equipment, load securing, legal guidelines and more.</p>
                                    </div></figcaption>

                            </figure>
                        </div>
                        <div className="col-md-6 pe-lg-5 mb-5 mb-lg-0 mb-md-0">
                            <figure>
                                <div className="image-item mb-4">
                                    <div className="w-0 bg-container bg-left-overlay">
                                        <div className="img-anim portfolio-img3"></div>
                                    </div>
                                </div>
                                <figcaption className=" wow fadeInUp" data-wow-delay="0.9s">
                                    <h5 className="f36">Travel App</h5>
                                    <div className="col-lg-8"> <p className="font-regular">A feature-packed app by the renowned brand Jaguar that lets users find dealers, place orders and request custom products or simply ask for quotes.</p>
                                    </div></figcaption>

                            </figure>
                        </div>
                        <div className="col-md-6 pe-lg-5 mb-5 mb-lg-0 mb-md-0  mt-6">
                            <figure>
                                <div className="image-item mb-4">
                                    <div className="w-0 bg-container bg-right-overlay">
                                        <div className="img-anim portfolio-img4"></div>
                                    </div>
                                </div>
                                <figcaption className=" wow fadeInUp" data-wow-delay="0.9s">
                                    <h5 className="f36">Primary School Chinese</h5>
                                    <div className="col-lg-8"> <p className="font-regular">An engaging educational app that helps and encourages primary school children to learn basic Mandarin Chinese through fun games, videos, books and other interactive activities.</p>
                                    </div></figcaption>

                            </figure>
                        </div>
                        <div className="col-md-6 pe-lg-5 mb-5 mb-lg-0 mb-md-0">

                            <figure>
                                <div className="image-item mb-4">
                                    <div className="w-0 bg-container bg-left-overlay">
                                        <div className="img-anim portfolio-img5"></div>
                                    </div>
                                </div>
                                <figcaption className=" wow fadeInUp" data-wow-delay="0.9s">
                                    <h5 className="f36">iRide</h5>
                                    <div className="col-lg-8"> <p className="font-regular">One-of-a-kind app for skiing and snowboarding. iRide app is packed with advanced features and functionalities that lets skiers and snowboarders compete.</p>
                                    </div></figcaption>

                            </figure>
                        </div>
                        <div className="col-md-6 pe-lg-5 mb-5 mb-lg-0 mb-md-0  mt-6">
                            <figure>
                                <div className="image-item mb-4">
                                    <div className="w-0 bg-container bg-right-overlay">
                                        <div className="img-anim portfolio-img6"></div>
                                    </div>
                                </div>
                                <figcaption className=" wow fadeInUp" data-wow-delay="0.9s" >
                                    <h5 className="f36">Eventrageous</h5>
                                    <div className="col-lg-8">  <p className="font-regular">Event planning and execution has never been so easy. Eventrageous serves as your go to platform for all your event management needs.</p></div>
                                </figcaption>

                            </figure>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default Portfolio